import gql from 'graphql-tag';

export const GET_COMPANIES = gql`
{
  listCompanies {
    id
    name
  }
}
`

/**
 * Gets the admins and their associated payment methods for the company.
 */
export const GET_ADMINS_FOR_COMPANY = gql`
query ListCompanyAdmins($companyId: ID!) {
  listCompanyAdmins(companyId: $companyId) {
    id
    firstName
    lastName
    email
    stripeCustomerId
    companyUserRoles {
      createdAt
    }
    paymentMethods {
      id
      stripeSourceId
      stripeStatus
      last4
      primary
      createdAt
    }
  }
}
`
