import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import InputMask from "react-input-mask";
import { Link, navigate } from '@reach/router';

import { CREATE_COMPANY } from '../../common/apiQueries';
import Header from '../../components/templates/Header';
import { useForm, TextInputFormFragment } from '../../common/forms';

function CompanyNew(props) {
  const [addCompany, ] = useMutation(CREATE_COMPANY, {
    onCompleted(data) {
      if (data.errors) {
        console.log(data.errors);
      } else {
        navigate(`/companies/${data.createCompany.company.id}`);
      }
    }
  });

  const breadcrumbTrail = [
    ['/', 'Home'],
    ['/companies', 'Companies'],
    ['', 'Add Company']
  ];

  const form = useForm();

  const onSubmit = (e) => {
    e.preventDefault();
    addCompany({
      variables: {
        company: form.values,
      }
    });
  }

  return (
      <div>
        <Header title='Add Company'
          titleButton={ <Link to='/companies' className='btn btn-danger btn-sm ml-3'>Cancel</Link> }
          breadcrumbs={ breadcrumbTrail } />
        <div className="card">
          <div className="card-body">
            <form onSubmit={onSubmit}>
              <TextInputFormFragment id='name' name='name' displayName='Company Name' value={ form.values.name } onChange={ form.onChange } required={ true } />
              <div className='form-group'>
                <label htmlFor='about'>About</label>
                <textarea className='form-control' id='about' name='about' value={ form.values.about } onChange={ form.onChange } placeholder='Description about your company' row='7' />
              </div>
              <TextInputFormFragment name='street1' displayName='Address' placeholder='1234 Main St' value={ form.values.street1 } onChange={ form.onChange } />
              <TextInputFormFragment name='street2' displayName='Address 2' placeholder='Apartment, studio, or floor' value={ form.values.street2 } onChange={ form.onChange } />
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="attr_city" className="col-form-label">City</label>
                  <input type="text" className='form-control' id="attr_city" name='city' value={ form.values.city } onChange={ form.onChange } />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="attr_state" className="col-form-label">State</label>
                  <input type="text" className="form-control" id="attr_state" name='state' value={ form.values.state } onChange={ form.onChange } />
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="attr_zip" className="col-form-label">Zip</label>
                  <InputMask className='form-control' id='attr_zip' mask='99999' name='zip' value={ form.values.zip } onChange={ form.onChange } />
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <label htmlFor="attr_phone_number" className="col-form-label">Phone Number</label>
                  <InputMask className='form-control' id='attr_phone_number' mask='(999) 999-9999' name='phoneNumber' value={ form.values.phoneNumber } onChange={ form.onChange } />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor="attr_fax_number" className="col-form-label">Fax Number</label>
                  <InputMask className='form-control' id='attr_fax_number' mask='(999) 999-9999' name='faxNumber' value={ form.values.faxNumber } onChange={ form.onChange } />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
  );
}

export default CompanyNew;
