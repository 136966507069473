import React from 'react';

function Card({ title, children, contentRight }) {
  return (
    <div className='card'>
      <div className='card-body'>
        { contentRight &&
          <div className='float-right'>
            { contentRight }
          </div>
        }
        <h4 className='header-title mt-0 mb-3'>{title}</h4>
        {children}
      </div>
    </div>
  )
}

export default Card;
