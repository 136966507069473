import Fuse from "fuse.js";

export function filterDiversExperiences(
  diversExperiences = [],
  filter = {
    name: "",
    divesPerformed: [],
    maximumDepth: [],
    timeSpentAtBottom: [],
    diveType: [],
    decompression: [],
    breathingApparatus: [],
    breathingMixture: [],
    condition: [],
    temperature: [],
    visibility: [],
    current: [],
    taskPerformed: [],
  }
) {
  let filteredList = [...diversExperiences];
  const fuse = new Fuse(filteredList, { keys: ["name"] });

  if (filter.name) {
    filteredList = fuse.search(filter.name).map((r) => r.item);
  }

  if (filter.divesPerformed.length) {
    filteredList = filteredList.filter(
      (d) =>
        d.divesPerformed >= filter.divesPerformed[0] &&
        d.divesPerformed <= filter.divesPerformed[1]
    );
  }

  if (filter.maximumDepth.length) {
    filteredList = filteredList.filter(
      (d) =>
        d.maximumDepth >= filter.maximumDepth[0] &&
        d.maximumDepth <= filter.maximumDepth[1]
    );
  }

  if (filter.timeSpentAtBottom.length) {
    filteredList = filteredList.filter(
      (d) =>
        d.timeSpentAtBottom >= filter.timeSpentAtBottom[0] &&
        d.timeSpentAtBottom <= filter.timeSpentAtBottom[1]
    );
  }

  if (filter.diveType.length) {
    filteredList = filteredList.filter((d) =>
      d.diveType.some((x) => filter.diveType.some((y) => x === y))
    );
  }

  if (filter.decompression.length) {
    filteredList = filteredList.filter((d) =>
      d.decompression.some((x) => filter.decompression.some((y) => x === y))
    );
  }

  if (filter.breathingApparatus.length) {
    filteredList = filteredList.filter((d) =>
      d.breathingApparatus.some((x) =>
        filter.breathingApparatus.some((y) => x === y)
      )
    );
  }

  if (filter.breathingMixture.length) {
    filteredList = filteredList.filter((d) =>
      d.breathingMixture.some((x) =>
        filter.breathingMixture.some((y) => x === y)
      )
    );
  }

  if (filter.condition.length) {
    filteredList = filteredList.filter((d) =>
      d.condition.some((x) => filter.condition.some((y) => x === y))
    );
  }

  if (filter.temperature.length) {
    filteredList = filteredList.filter((d) =>
      d.temperature.some((x) => filter.temperature.some((y) => x === y))
    );
  }

  if (filter.visibility.length) {
    filteredList = filteredList.filter((d) =>
      d.visibility.some((x) => filter.visibility.some((y) => x === y))
    );
  }

  if (filter.current.length) {
    filteredList = filteredList.filter((d) =>
      d.current.some((x) => filter.current.some((y) => x === y))
    );
  }

  if (filter.taskPerformed.length) {
    filteredList = filteredList.filter((d) =>
      d.taskPerformed.some((x) => filter.taskPerformed.some((y) => x === y))
    );
  }

  return filteredList;
}
