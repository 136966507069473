// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:b7cfcc8e-238f-4e55-95eb-5607ff82d0d1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_HBxGWcZk9",
    "aws_user_pools_web_client_id": "42oneut3q5maaffchpcoa4hkq",
    "aws_user_files_s3_bucket": "divelocker",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_content_delivery_bucket": "divelocker-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://divelocker-dev.s3-website-us-west-2.amazonaws.com"
};


export default awsmobile;
