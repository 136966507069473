import React, { Component, Fragment } from 'react'

import { Link } from "@reach/router";
import {
  isCompanyAdmin,
  isSiteAdmin,
} from '../common/permissions';


class LeftNavigation extends Component {

  linkTo(text, logo, path) {
    return (
      <li className="side-nav-item">
        <Link to={ path } className="side-nav-link">
          <i className={ logo }></i>
          <span>{ text }</span>
        </Link>
      </li>
    );
  }

  render() {
    const permissionCompanyAdmin = isCompanyAdmin(this.props.user);
    const permissionSiteAdmin = isSiteAdmin(this.props.user);

    return (
      <div className="left-side-menu">
        <div className="slimscroll-menu">
          <Link to="/" className="logo text-center">
            <span className="logo-lg">
              <img src={ require('../assets/images/divelocker-logo-leftnav.svg') } alt="DiveLocker logo" height="140" />
            </span>
            <span className="logo-sm">
              <img src={ require('../assets/images/divelocker-logo-leftnav.svg') } alt="DiveLocker logo" height="140" />
            </span>
          </Link>
          <ul className="metismenu side-nav">
            { this.linkTo('Dashboard', 'dripicons-meter', '/') }
            { this.linkTo('Dives', 'mdi mdi-water', '/dives') }
            { this.linkTo('My Divers', 'dripicons-user', '/divers') }
            { this.linkTo('Recruit Divers', 'dripicons-user-group', '/divers/search') }
            { permissionCompanyAdmin && this.linkTo('Account', 'dripicons-briefcase', '/account') }
            { permissionSiteAdmin &&
              <Fragment>
                <li className="side-nav-title side-nav-item">ADMIN</li>
                { this.linkTo('Companies', 'dripicons-briefcase', '/companies') }
                { this.linkTo('Stripe Subscription', 'dripicons-briefcase', '/subscribe') }
              </Fragment>
            }
          </ul>
        </div>
      </div>
    );
  }
}

export default LeftNavigation;
