import React from 'react'
import { useQuery } from '@apollo/react-hooks';

import { GET_DIVE } from '../../common/apiQueries';
import Header from '../../components/templates/Header';
import Card from '../../components/Card';
import { DATE_FORMAT } from '../../utils/constants';

function TileCard(props) {
  return (
    <div className='col-lg-4'>
      <div className="card tilebox-one">
        <div className="card-body"> <i className="dripicons-basket float-right text-muted"></i>
          <h6 className="text-muted text-uppercase mt-0">{props.title}</h6>
          <h2 className="m-b-20">{props.value || ''}&nbsp;</h2>
        </div>
      </div>
    </div>
  );
}

function Dive(props) {
  const { loading, error, data } = useQuery(GET_DIVE, { variables: { id: props.diveId } });

  if (loading) return null;
  if (error) return <div>There was an issue {error.messages}</div>;

  const dive = data.getDive;

  if (!dive) return <div>Dive info missing. Please reach out to support</div>;

  const breadcrumbTrail = [
    ['/', 'Home'],
    ['/dives', 'Dives'],
    ['', 'Dive']
  ];

  const diveDate = (new Date(dive.diveDateTime)).toLocaleDateString("en-US", DATE_FORMAT);

  return (
    <div>
      <Header title={ 'Dive Information' } breadcrumbs={ breadcrumbTrail } />
      <div className='row'>
        <div className='col-lg-4'>
          <Card title='Diver'>
            <div className="text-left">
              <p className="text-muted"><strong>Name :</strong> <span className="ml-2">{dive.user.firstName} { dive.user.lastName} ({dive.user.email})</span></p>
            </div>
          </Card>
        </div>
        <div className='col-lg-8'>
          <Card title={ dive.title }>
            <div className="text-left">
              { dive.jobType && <p className="text-muted"><strong>Job Type :</strong><span className="ml-2">{ dive.jobType }</span></p> }
              <p className="text-muted"><strong>Dive Date :</strong><span className="ml-2">{ diveDate }</span></p>
              <p className="text-muted"><strong>Location :</strong> <span className="ml-2">{ dive.city }, { dive.state}</span></p>
            </div>
          </Card>
        </div>
        { dive.logImage &&
          (<div className='col-lg-8'>
            <Card title='Log Image'>
              <img src={ dive.logImage } alt='Dive log' />
            </Card>
          </div>)
        }
        <div className='row'>
          <TileCard title='Decompression' value={ dive.decompression } />
          <TileCard title='Breathing Apparatus' value={ dive.breathingApparatus } />
          <TileCard title='Breathing Mixture' value={ dive.breathingMixture } />
          <TileCard title='Conditions' value={ dive.condition } />
          <TileCard title='Temperature' value={ dive.temperature } />
          <TileCard title='Visibility' value={ dive.visibility } />
          <TileCard title='Current' value={ dive.current } />
        </div>
      </div>
    </div>
  );
}

export default Dive;
