import React, { useContext } from "react";
import { ModalContext } from "./Modal";

function Dialog(
  props = {
    title: "",
    confirmLabel: "OK",
    onConfirm: () => void 0,
    cancelLabel: "Cancel",
    onCancel: () => void 0,
  }
) {
  const { closeModal } = useContext(ModalContext);

  return (
    <div className="modal-content">
      <div className="modal-header">
        {!!props.title && <h5 className="modal-title">{props.title}</h5>}
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => {
            closeModal();
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{props.children}</div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={() => {
            props.onCancel && props.onCancel();
            closeModal();
          }}
        >
          {props.cancelLabel || "Cancel"}
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => {
            props.onConfirm && props.onConfirm();
            closeModal();
          }}
        >
          {props.confirmLabel || "OK"}
        </button>
      </div>
    </div>
  );
}

export default Dialog;
