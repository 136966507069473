import React, { Fragment } from 'react';

import Card from './Card';
import { DATE_FORMAT } from '../utils/constants';

function ActiveSubscriptionInfo(props) {
  const { currentSubscription } = props.company;

  return (
    <Fragment>
      <p className="text-muted"><strong>Membership :</strong><span className="ml-2">{ currentSubscription.planName }</span></p>
      <p className="text-muted"><strong>{ currentSubscription.description }</strong></p>
      <p className="text-muted"><strong>Purchased Company Seats :</strong><span className="ml-2">{ currentSubscription.companySeats }</span></p>
      <p className="text-muted"><strong>Purchased Diver Seats :</strong><span className="ml-2">{ currentSubscription.diverSeats }</span></p>
      <p className="text-muted"><strong>Next Billing Cycle Begins :</strong><span className="ml-2">{ currentSubscription.currentPeriodEnd }</span></p>
    </Fragment>
  );
}

function NoSubscription() {
  return (
    <p className="text-muted"><span className="ml-2">No active subscription</span></p>
  );
}

function AddressInformation(props) {
  const { company } = props;

  if (!(company.street1 && company.street2 && company.city && company.state && company.zipCode)) {
    return null;
  }

  return (
    <Fragment>
        <h5>Address</h5>
        { company.street1 && <p className="text-muted"><strong>Street:</strong><span className="ml-2">{ company.street1} </span></p> }
        { company.street2 && <p className="text-muted"><strong>Street2:</strong><span className="ml-2">{ company.street2} </span></p> }
        { company.city && <span className="ml-2">{ company.city }, { company.state }&nbsp;&nbsp;{company.zipCode}</span> }
    </Fragment>
  );
}

function CompanyProfileCard(props) {
  const { company } = props;
  return(
    <Card title={ company.name }>
      <p>{ company.about }</p>
      <div className="text-left">
        { company.phoneNumber && <p className="text-muted"><strong>Phone Number :</strong><span className="ml-2">{ company.phoneNumber} </span></p> }
        { company.fax && <p className="text-muted"><strong>Fax Number :</strong><span className="ml-2">{ company.fax} </span></p> }
        <AddressInformation company={ company } />
        <p className="text-muted"><strong>Join Date :</strong><span className="ml-2">{ (new Date(company.createdAt)).toLocaleDateString("en-US", DATE_FORMAT) } </span></p>
        { company.currentSubscription ? <ActiveSubscriptionInfo company={company} /> : <NoSubscription /> }
      </div>
    </Card>
  );
}

export default CompanyProfileCard;
