import React from 'react'

import {
  CardElement,
  injectStripe,
} from 'react-stripe-elements';

class SubscribeForm extends React.Component {

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(event) {
    let {token} = await this.props.stripe.createToken({name: "Name"});

    const mutationQuery = "mutation createSubscription($input: CreateSubscriptionInput!) {\n  createSubscription(input: $input) {\n    errors\n  }\n}"
    const body = {
      query: mutationQuery,
      variables: {
        input: {
          email: "phiyan@gmail.com",
          stripeToken: token.id,
          coupon: null,
          subscriptionPlan: "plan_Fxotj9GLXzmrvH",
        }
      }};

    let response = await fetch("http://localhost:3001/graphql", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(body),
    });

    if (response.ok) console.log("Purchase Complete!")
  }

  render() {
    return (
      <div className="checkout">
        <p>Would you like to complete the purchase?</p>
        <CardElement />
        <button onClick={this.submit}>Purchase</button>
      </div>
    );
  }
}

export default injectStripe(SubscribeForm);
