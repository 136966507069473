import React, { Fragment } from 'react'
import { useQuery } from '@apollo/react-hooks';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, navigate } from "@reach/router";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import { GET_DIVERS } from '../../common/apiQueries';
import Header from '../../components/templates/Header';


function visitItem(event, row, rowIndex) {
  navigate(`/divers/${row.id}`);
}

function avatarFormatter() {
  return (
    <span className="table-user">
      <img src={ require('../../assets/images/users/avatar-1.jpg') } alt="" className="rounded-circle" />
    </span>
  );
}

function Divers(props) {
  let variables = {}
  if (props.companyId) {
    variables.companyId = props.companyId;
  }

  const { loading, error, data } = useQuery(GET_DIVERS, { variables: variables });

  if (loading) return null;
  if (error) return <div>Sorry, there was an error {error.message}.</div>;

  const columns = [
    { text: '', dataField: 'image', formatter: avatarFormatter, headerStyle: (col, colIndex) => {return { width: '60px', textAlign: 'center' };}},
    { text: 'Email', dataField: 'email', filter: textFilter() },
    { text: 'First Name', dataField: 'firstName', filter: textFilter() },
    { text: 'Last Name', dataField: 'lastName', filter: textFilter() },
  ];

  const breadcrumbTrail = [
    ['/', 'Home'],
    ['', 'Divers'],
  ];

  const rowEvents = {
    onClick: visitItem,
  }

  let divers = data.listUsers;
  if (divers === null) {
    divers = [];
  }

  const actions = (
    <Fragment>
      <Link to='/divers/new' className='btn btn-success btn-sm ml-3'>Add New</Link>
      <Link to='/divers/internal' className='btn btn-secondary btn-sm ml-3'>Search My Divers</Link>
      <Link to='/divers/search' className='btn btn-secondary btn-sm ml-3'>Recruit Divers</Link>
    </Fragment>
  );

  return (
    <div>
      <Header
        title="Company Divers"
        titleButton={actions}
        breadcrumbs={breadcrumbTrail}
      />
      <div className="card">
        <div className="card-body">
          <div id="notice">
            <p>
              This is your company’s roster of commercial divers. Click on a
              diver to view dive logs, certs, and contact information. Or select
              ‘Recruit Divers’ to search for divers outside of your company.
            </p>
          </div>
          <BootstrapTable
            bootstrap4
            condensed
            bordered={false}
            keyField="id"
            data={divers}
            columns={columns}
            rowEvents={rowEvents}
            wrapperClasses={"table-hover"}
            filter={filterFactory()}
          />
        </div>
      </div>
    </div>
  );
}

export default Divers;
