import React from 'react';
import { Link, navigate } from '@reach/router';

import Card from '../components/Card';
import defaultAvatar from '../assets/images/users/avatar-2.jpg';

function showUser(event) {
  const userId = event.currentTarget.getAttribute('data-id');
  navigate(`/divers/${userId}`);
}

function UserRow(props) {
  const user = props.user;

  // TODO: turn into legit action
  return (
    <tr data-id={ user.id } onClick={ showUser }>
      <td className="table-user">
        <img src={ user.profilePhoto || defaultAvatar } alt="table-user" className="mr-2 rounded-circle" />
        { user.firstName } { user.lastName }
      </td>
      <td>{ user.email }</td>
      <td className="table-action">
        <a href="/a" className="action-icon"> <i className="mdi mdi-delete"></i></a>
      </td>
    </tr>
  );
}

function CompanyUserList(props) {
  const addNewTeamMember = <Link to={`/companies/${props.companyId}/members/new`} className='btn btn-success btn-sm ml-3'>Add New</Link>
  return (
    <Card title='Team' contentRight={ addNewTeamMember }>
      <table className="table table-striped table-centered mb-0">
        <thead>
          <tr>
            <th>Team Member</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          { props.users.map(user => <UserRow user={ user } key={ user.id } />) }
        </tbody>
      </table>
    </Card>
  );
}

export default CompanyUserList;
