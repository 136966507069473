import React from 'react';
import footerLogo from "../assets/images/logo-footer.png";
function ContentFooter(props) {
  return (
    <div id="footer">
      <div>{new Date().getFullYear()} &copy; DiveLocker</div>
      <div>
        <img
          src={footerLogo}
          alt="DiveLocker logo"
        />
      </div>
    </div>
  );
}

export default ContentFooter;
