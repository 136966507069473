import React, { useContext } from "react";
import { ModalContext } from "./Modal";

function BaseModal(props = { title: "" }) {
  const { closeModal } = useContext(ModalContext);

  return (
    <div className="modal-content">
      <div className="modal-header">
        {!!props.title && <h5 className="modal-title">{props.title}</h5>}
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{props.children}</div>
    </div>
  );
}

export default BaseModal;
