import React, { Component } from 'react'

import {
  Elements,
  StripeProvider,
} from 'react-stripe-elements';

import SubscribeForm from "./SubscribeForm";

class Subscribe extends Component {
  render() {
    return (
      <StripeProvider apiKey="pk_test_dRFJzpjSnQpTXaSDncfqayOR">
        <div className="example">
          <h1>React Stripe Elements Example</h1>
          <Elements>
            <SubscribeForm />
          </Elements>
        </div>
      </StripeProvider>
    );
  }
}

export default Subscribe;
