import React, { useContext, useState } from "react";

const ModalContext = React.createContext({
  openModal: () => {},
  closeModal: () => {},
});

const Modal = (props) => {
  let { options } = useContext(ModalContext);

  return (
    <>
      <div
        className={`modal fade ${options.isOpen ? "show" : ""}`}
        tabIndex={-1}
        style={{ ...(options.isOpen ? { display: "block" } : {}) }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          {props.children}
        </div>
      </div>
      {options.isOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

function useModal() {
  const [options, setOptions] = useState({
    isOpen: false,
    content: null,
    onClose: () => {},
  });

  let openModal = (options) => {
    setOptions({
      ...options,
      isOpen: true,
    });
  };

  let closeModal = () => {
    setOptions({
      isOpen: false,
      content: null,
      onClose: () => {},
    });
  };

  return { openModal, closeModal, options };
}

const ModalProvider = ({ children }) => {
  let { openModal, closeModal, options } = useModal();
  return (
    <ModalContext.Provider value={{ openModal, closeModal, options }}>
      {children}
      <Modal>{options?.content}</Modal>
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };
