import React from 'react'
import { Link } from "@reach/router";

import Header from '../../components/templates/Header';
import DiveForm from '../../components/DiveForm';

function DiveNew(props) {
  const breadcrumbTrail = [
    ['/', 'Home'],
    ['/dives', 'Dives'],
    ['', 'Add New Dive']
  ];

  return (
    <div>
      <Header
        title="Add New Dive"
        titleButton={
          <Link to="/dives" className="btn btn-danger btn-sm ml-3">
            Cancel
          </Link>
        }
        breadcrumbs={breadcrumbTrail}
      />
      <div className="card">
        <div className="card-body">
          <div id="notice">
            <p>
              Select the diver to add a new dive, then note the dates and location
              of the dive. Locations are calculated by entering latitude and
              longitude coordinates.
            </p>
          </div>
          <DiveForm
            companyId={props.companyId}
            isAdmin={props.isAdmin}
            cognitoUser={props.cognitoUser}
          />
        </div>
      </div>
    </div>
  );
}

export default DiveNew;

