import React, { Component } from 'react';
import { Auth } from 'aws-amplify'
import App from './App';
import { getUser } from "./graphql/queries";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';


class SessionManager extends Component {
  constructor() {
    super();
    this.state = {
      cognitoUser: null,
      user: null,
      client: null,
    }
  }

  async componentDidMount() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    this.setState({
      cognitoUser: cognitoUser,
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.cognitoUser === null && this.state.cognitoUser !== prevState.cognitoUser) {
      const client = this.createClient();
      this.loadUser(this.state.cognitoUser.attributes.sub, client);
    }
  }

  createClient() {
    const request = async (operation) => {
      operation.setContext({
        headers: {
          authorization: `Bearer ${this.state.cognitoUser.signInUserSession.accessToken.jwtToken}`,
          refresh: `Refresh ${this.state.cognitoUser.signInUserSession.refreshToken.token}`,
        },
      });
    };

    const requestLink = new ApolloLink((operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(oper => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
    );

    const client = new ApolloClient({
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              ),
            );
          if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        requestLink,
        new HttpLink({
          uri: process.env.REACT_APP_DIVELOCKER_API_ENDPOINT,
          credentials: 'same-origin'
        }),
      ]),
      cache: new InMemoryCache(),
    });

    return client;
  }

  async loadUser(cognitoId, client) {
    const q = getUser(cognitoId);
    const user = await client.query({ query: q });

    this.setState({
      user: user.data.getUser,
      client: client,
    })
  }

  render() {
    let finishedLoading = (<div />);

    if (this.state.user) {
      finishedLoading = (<App client={this.state.client} user={this.state.user} cognitoUser={ this.state.cognitoUser } />);
    }

    return (
      <div>
        { finishedLoading }
      </div>
    );
  }
}

export default SessionManager;
