import React, { useContext } from "react";

import defaultAvatar from "../assets/images/users/avatar-1.jpg";
import { ModalContext } from "./modal/Modal";
import BaseModal from "./modal/BaseModal";

function InfoItem(props) {
  return (
    <div className={"info-item border-bottom"}>
      <h5>{props.label}</h5>
      <span>{props.value}</span>
    </div>
  );
}

export default function DiverExperienceCard(
  props = { dive: {}, onClick: () => {} }
) {
  const { openModal } = useContext(ModalContext);

  const dive = props.dive || {};

  function showCurrent(e) {
    e.stopPropagation();
    openModal({
      content: <BaseModal title={"Current"}>{dive.current}</BaseModal>,
    });
  }

  function showTasks(e) {
    e.stopPropagation();
    openModal({
      content: (
        <BaseModal title={"Tasks performed"}>
          {dive.taskPerformed.join(", ")}
        </BaseModal>
      ),
    });
  }

  return (
    <div
      className="card text-center diver-experience-card"
      onClick={props.onClick}
    >
      <div className="card-body">
        <div className="dives-wrapper text-center">
          <h3 className={"text-success"}>{dive.divesPerformed}</h3>
          <span>Dives</span>
        </div>
        <div className={"avatar"}>
          <img src={defaultAvatar} alt="" className="rounded-circle" />
        </div>
        <h3>{dive.name}</h3>
        <div className={"row justify-content-center"}>
          {dive.diveType.map((dt, index) => (
            <span key={index} className="badge badge-success mx-1">
              {dt}
            </span>
          ))}
          {!dive.diveType.length && (
            <span className="badge badge-success mx-1">N/A</span>
          )}
        </div>
        <div className="info-wrapper mt-2">
          <div className="row no-gutters">
            <div className="col">
              <InfoItem
                label={"Max. Time Spent"}
                value={dive.timeSpentAtBottom || "N/A"}
              />
            </div>
            <div className="col">
              <InfoItem
                label={"Max. Depth"}
                value={dive.maximumDepth || "N/A"}
              />
            </div>
          </div>
          <InfoItem
            label={"Decompression"}
            value={dive.decompression.join(", ") || "N/A"}
          />
          <InfoItem
            label={"Breathing Apparatus"}
            value={dive.breathingApparatus.join(", ") || "N/A"}
          />
          <InfoItem
            label={"Breathing Mixture"}
            value={dive.breathingMixture.join(", ") || "N/A"}
          />
          <InfoItem
            label={"Condition"}
            value={dive.condition.join(", ") || "N/A"}
          />
          <InfoItem
            label={"Temperature"}
            value={dive.temperature.join(", ") || "N/A"}
          />
          <InfoItem
            label={"Visibility"}
            value={dive.visibility.join(", ") || "N/A"}
          />
        </div>
        <div className={"row mt-1"}>
          <div className="col">
            <button
              type="button"
              className="btn text-success"
              onClick={showCurrent}
            >
              <span className="dripicons-vibrate mr-1"></span>
              Current
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              className="btn text-success"
              onClick={showTasks}
            >
              <span className="dripicons-checkmark mr-1"></span>
              Tasks
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
