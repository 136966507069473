import React from 'react';
import { Auth } from 'aws-amplify'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import defaultAvatar from '../assets/images/users/avatar-1.jpg';

function ContentHeader({ user }) {
  return(
    <div className="navbar-custom">
      <ul className="list-unstyled topbar-right-menu float-right mb-0">
        <li className="dropdown notification-list">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="nav-link dropdown-toggle nav-user arrow-none mr-0" data-toggle="dropdown">
              <span className="account-user-avatar">
                <img src={ user.profilePhoto || defaultAvatar } alt="" className="rounded-circle" />
              </span>
              <span>
                <span className="account-user-name">
                  {user.firstName || user.email}
                </span>
                <span className="account-position">&nbsp;</span>
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown">
              <DropdownItem onClick={() => {
                Auth.signOut()
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((e) => console.log(e))
              }}>
                Sign Out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    </div>
  );
}

export default ContentHeader;
