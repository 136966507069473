import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useQuery } from "@apollo/react-hooks";
import { Link, navigate } from "@reach/router";

import { bootstrapTableDateFormatter } from "../../common/tables";
import { GET_DIVES } from "../../common/apiQueries";
import Header from "../../components/templates/Header";

function visitItem(event, row, rowIndex) {
  navigate(`/dives/${row.id}`);
}

function mapDives(dives) {
  return dives.map((d) => {
    if (d.user) {
      d.name = `${d.user.firstName} ${d.user.lastName}`;
    }
    return d;
  });
}

function Dives(props) {
  let variables = {};
  if (props.companyId) {
    variables.companyId = props.companyId;
  }

  const { loading, error, data } = useQuery(GET_DIVES, { variables });
  if (loading) return null;
  if (error) return <div>Sorry, there was an error {error.message}.</div>;

  const breadcrumbTrail = [
    ["/", "Home"],
    ["", "Dives"],
  ];

  const columns = [
    { text: "Name", dataField: "name" },
    { text: "City", dataField: "city" },
    { text: "State", dataField: "state" },
    {
      text: "Date",
      dataField: "diveDateTime",
      formatter: bootstrapTableDateFormatter,
    },
    { text: "Longitude", dataField: "lng" },
    { text: "Latitude", dataField: "lat" },
  ];

  const rowEvents = {
    onClick: visitItem,
  };
  const dives = mapDives(data.listDives || []);

  return (
    <div>
      <Header
        title="Dives"
        titleButton={
          <Link to="/dives/new" className="btn btn-success btn-sm ml-3">
            Add New
          </Link>
        }
        breadcrumbs={breadcrumbTrail}
      />
      <div className="card">
        <div className="card-body">
          <div id="notice">
            <p>
              This is your company’s logbook. Click on an item below to view
              logs for each dive or select ‘Add New’ above to submit a new dive.
            </p>
          </div>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={dives}
            columns={columns}
            rowEvents={rowEvents}
            wrapperClasses={"table-hover"}
          />
        </div>
      </div>
    </div>
  );
}

export default Dives;
