// DATE FORMAT Constant
export const DATE_FORMAT = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

// DIVE VALUE CONSTANTS
export const APPARATUS_VALUES = ['Hard Hat', 'Band Mask', 'Other'];
export const MIX_VALUES = ['Air', 'Nitrox', 'Mixed Gas'];
export const CONDITION_VALUES = ['mid-water', 'hard', 'soft'];
export const TEMPERATURE_VALUES = ['cold', 'normal', 'hot'];
export const VISIBILITY_VALUES = ['none', 'poor', 'moderate', 'good'];
export const CURRENT_VALUES = ['0', '< .5', '.5 - 1.5', '1.5+'];
export const DECOMPRESSION_VALUES = ['NoD', 'SurD', 'In Water'];

export const DIVE_TYPE_VALUES = ['SSA', 'Scuba', 'SAT'];

export const DIVE_TYPE_VALUE_MAP = {
  SSA: 'SSA',
  Scuba: 'scuba',
  SAT: 'SAT',
};

export const DIVE_TASKS = [
  'Welding',
  'Burning',
  'Inspection',
  'Jetting',
  'Flange Install',
  'Salvage',
  'Hot Tap',
  'Pile repair',
  'Survey',
  'Demo',
  'General Construction',
  'Nuclear',
  'Core drilling',
  'Rigging',
  'USACE',
  'Hazmat',
  'Debris/Sediment Removal',
  'Hydro',
  'Other',
]

export const DIVE_TASKS_MAPPING = {
  welding: 'Welding',
  burning: 'Burning',
  inspection: 'Inspection',
  jetting: 'Jetting',
  flangeInstall: 'Flange Install',
  salvage: 'Salvage',
  hotTap: 'Hot Tap',
  pileRepair: 'Pile repair',
  survey: 'Survey',
  demo: 'Demo',
  generalConstruction: 'General Construction',
  nuclear: 'Nuclear',
  coreDrilling: 'Core drilling',
  rigging: 'Rigging',
  usace: 'USACE',
  hazmat: 'Hazmat',
  debrisSedimentRemoval: 'Debris/Sediment Removal',
  hydro: 'Hydro',
  other: 'Other',
};
