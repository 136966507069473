import React from 'react'
import { Link } from "@reach/router";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

/**
 * Assumes the last item to be the active item
 */
const buildBreadcrumbs = (breadcrumbs) => {
  if (!breadcrumbs || breadcrumbs.length === 0) {
    return null;
  }

  const nonActiveEntries = breadcrumbs.slice(0, breadcrumbs.length - 1);
  let entries = nonActiveEntries.map((linkInformation, idx) => (<BreadcrumbItem key={idx}><Link to={linkInformation[0]}>{linkInformation[1]}</Link></BreadcrumbItem>))
  const activeEntry = breadcrumbs[breadcrumbs.length - 1];
  entries.push(<BreadcrumbItem key={breadcrumbs.length} active>{ activeEntry[1] }</BreadcrumbItem>);
  return entries;
};

const Header = (props) => {
  const { title, titleButton, breadcrumbs } = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <div className="page-title-right">
            <Breadcrumb tag="nav" className="m-0">
              { buildBreadcrumbs(breadcrumbs) }
            </Breadcrumb>
          </div>
          <h4 className="page-title">
            { title }
            { titleButton }
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Header ;
