import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { DATE_FORMAT } from '../../utils/constants';
import { GET_DIVER_CERTIFICATION } from '../../common/apiQueries';
import Header from "../../components/templates/Header";
import Card from '../../components/Card';


function formatDate(dateString) {
  if (dateString) {
    return (new Date(dateString)).toLocaleDateString("en-US", DATE_FORMAT);
  }

  return dateString;
}

function DiverCertificationShow(props) {
  const { loading, error, data } = useQuery(GET_DIVER_CERTIFICATION, { variables: { id: props.id } });

  if (loading) return null;
  if (error) return <div>There was an issue {error.messages}</div>;


  const diverCertification = data.getDiverCertification;
  const diverName = diverCertification.user.firstName ? diverCertification.user.firstName : diverCertification.user.email;
  const breadcrumbTrail = [
    ['/', 'Home'],
    ['/divers', 'Divers'],
    [`/divers/${diverCertification.user.id}`, diverName],
    ['', 'Certification']
  ];

  return (
    <div>
      <Header title={ diverCertification.certificationType } breadcrumbs={ breadcrumbTrail } />
      <div className='row'>
        <div className='col-lg-4'>
          <Card title='Info'>
            <p className="text-muted"><strong>Issuing Body :</strong><span className="ml-2">{ diverCertification.issuingBody }</span></p>
            <p className="text-muted"><strong>Date Issued :</strong><span className="ml-2">{ formatDate(diverCertification.dateIssue) }</span></p>
            <p className="text-muted"><strong>Date Expiring :</strong><span className="ml-2">{ formatDate(diverCertification.dateExpired) }</span></p>
          </Card>
        </div>
        <div className='col-lg-8'>
          <Card title='Photo of Certification'>
            { diverCertification.certificateImage && <img src={ diverCertification.certificateImage } alt='Certification' style={ { width: '100%' } } /> }
          </Card>
        </div>
      </div>
    </div>
  );
}

export default DiverCertificationShow;
