import React from 'react'
import defaultAvatar from '../../assets/images/users/avatar-1.jpg';
import { DATE_FORMAT } from '../../utils/constants';

const DiverCard = (props) => {
  let locationDescription = undefined;
  if (props.city && props.state) {
    locationDescription = `${props.city}, ${props.state}`;
  } else if (props.city || props.state) {
    locationDescription = props.city || props.state;
  }

  const joinDate = (new Date(props.joinedAt)).toLocaleDateString("en-US", DATE_FORMAT);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card" style={ { backgroundColor: '#60aecd'} }>
              <div className="card-body profile-user-box">
                  <div className="row">
                      <div className="col-sm-8">
                          <div className="media d-flex align-items-center justify-content-start">
                              <span className="float-left m-2 mr-4">
                                <img src={ props.profilePhoto || defaultAvatar } alt="" className="rounded-circle img-thumbnail" style={{height: '100px'}}/>
                              </span>
                              <div className="media-body">
                              <div>
                                  <h4 className="mt-1 mb-1 text-white">{props.title}</h4>
                                  <ul className="mb-0 list-inline text-light">
                                    <li className="list-inline-item mr-3">
                                      { locationDescription && <h5 className="mb-1">{ locationDescription }</h5> }
                                      <p className="mb-0 font-13 text-white-50">Joined { joinDate }</p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-sm-4 d-flex justify-content-end">
                          <div className="d-flex align-items-center justify-content-end">
                              {props.children}
                          </div>
                      </div>
                  </div>

              </div>
          </div>
      </div>
  </div>
  );
};

export default DiverCard;
