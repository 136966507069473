import gql from 'graphql-tag';

/** Dashboard Query **/
export const DASHBOARD_QUERY = gql`
  query GetDashboardInformation {
    listUsers {
      id
      email
      firstName
      lastName
      country
      state
      profilePhoto
      diverProfile {
        availability
        unionMembership
        yearsOfExperience
      }
    }
    getDiverCount
    getDiveCount
    getCountsByMonthYear
  }
`;

export const DASHBOARD_QUERY_WITH_COMPANY = gql`
  query GetDashboardInformation($companyId: ID!) {
    listUsers(companyId: $companyId) {
      id
      email
      firstName
      lastName
      country
      state
      profilePhoto
      diverProfile {
        availability
        unionMembership
        yearsOfExperience
      }
    }
    getCompany(id: $companyId) {
      name
    }
    getDiverCount(companyId: $companyId)
    getDiveCount(companyId: $companyId)
    getCountsByMonthYear(companyId: $companyId)
  }
`;


/** Diver Queries **/
export const GET_DIVERS = gql`
query listDivers($companyId: ID) {
  listUsers(companyId: $companyId) {
    id
    email
    firstName
    lastName
    country
    state
    profilePhoto
  }
}
`;

export const CREATE_DIVER = gql`
mutation CreateUser($user: CreateUserInput!) {
  createUser(input: $user) {
    user {
      id
      firstName
      lastName
      street
      state
      country
      zip
      phone
      email
      profilePhoto
    }
  }
}
`

export const GET_DIVER = gql`
query User($id: ID!) {
  getUser(id: $id, cognitoId: null) {
    id
    firstName
    lastName
    street
    state
    country
    zip
    phone
    email
    profilePhoto
    createdAt
    companies {
      id
      name
    }
    companyUserInvitations {
      id
      respondedAt
      accepted
      company {
        id
      }
    }
    diverCertifications {
      id
      certificationType
      issuingBody
      dateIssue
      dateExpired
      certificateImage
      user {
        id
      }
    }
    dives {
      id
      city
      state
      diveDateTime
    }
    companyUserRoles {
      id
      company {
        id
        name
      }
      role
      createdAt
    }
  }
}
`

export const INVITE_DIVER = gql`
mutation InviteDiver($input: CreateCompanyUserInvitationInput!) {
  createCompanyUserInvitation(input: $input) {
    companyUserInvitation {
      id
      respondedAt
      accepted
      company {
        id
      }
    }
  }
}
`;

export const GET_DIVER_EXPERIENCES = gql`
{
  listDivers {
    user {
      id
      firstName
      lastName
    }
    breathingApparatus
    breathingMixture
    condition
    current
    decompression
    diveType
    jobType
    maximumDepth
    mode
    taskPerformed
    temperature
    timeSpentAtBottom
    visibility
    divesPerformed
  }
}
`;

export const GET_COMPANY_DIVER_EXPERIENCES = gql`
  {
    listCompanyDivers {
      user {
        id
        firstName
        lastName
      }
      breathingApparatus
      breathingMixture
      condition
      current
      decompression
      diveType
      jobType
      maximumDepth
      mode
      taskPerformed
      temperature
      timeSpentAtBottom
      visibility
      divesPerformed
    }
  }
`;


/** Diver Certification **/
export const GET_DIVER_CERTIFICATION = gql`
query GetDiverCertification($id: ID!) {
  getDiverCertification(id: $id) {
    certificationType
    issuingBody
    dateIssue
    dateExpired
    certificateImage
    user {
      firstName
      lastName
      email
    }
  }
}
`;


/** Dive Queries **/
export const GET_DIVES = gql`
query listDives($companyId: ID) {
  listDives(companyId: $companyId) {
    id
    jobType
    diveDateTime
    city
    state
    zip
    lng
    lat
    diveType
    maximumDepth
    timeSpentAtBottom
    mode
    decompression
    breathingApparatus
    breathingMixture
    taskPerformed
    condition
    temperature
    visibility
    current
    logImage
    affiliateCompanyName
    user {
      id
      firstName
      lastName
      email
    }
    company {
      id
      name
    }
    job {
      clientName
      projectName
    }
  }
}
`;

export const CREATE_DIVE = gql`
mutation CreateDive($dive: CreateDiveInput!) {
  createDive(input: $dive) {
    dive {
      id
      jobType
      diveDateTime
      city
      state
      zip
      logImage
      user {
        id
        firstName
        lastName
        email
      }
      company {
        id
        name
      }
    }
    errors
  }
}
`

export const UPDATE_DIVE = gql`
mutation UpdateDive($dive: UpdateDiveInput!) {
  updateDive(input: $dive) {
    dive {
      id
      jobType
      diveDateTime
      city
      state
      zip
      logImage
      user {
        id
        firstName
        lastName
        email
      }
      company {
        id
        name
      }
    }
  }
}
`

export const GET_DIVE = gql`
query Dive($id: ID!) {
  getDive(id: $id) {
    id
    jobType
    diveDateTime
    city
    state
    zip
    diveType
    decompression
    breathingApparatus
    breathingMixture
    taskPerformed
    condition
    temperature
    visibility
    current
    logImage
    affiliateCompanyName
    user {
      id
      firstName
      lastName
      email
    }
    company {
      id
      name
    }
    job {
      clientName
      projectName
    }
  }
}
`

/** Company Queries **/
export const GET_COMPANIES = gql`
{
  listCompanies {
    id
    name
  }
}
`

export const CREATE_COMPANY = gql`
mutation CreateCompany($company: CreateCompanyInput!) {
  createCompany(input: $company) {
    company {
      id
      name
    }
  }
}
`

export const GET_COMPANY = gql`
query Company($id: ID!) {
  getCompany(id: $id) {
    id
    name
    about
    website
    street1
    street2
    city
    state
    zip
    phoneNumber
    phoneNumber2
    faxNumber
    createdAt
    currentSubscription {
      id
      planName
      companySeats
      diverSeats
      startedAt
      currentPeriodEnd
    }
  }
  listUsers(companyId: $id, roles: ["admin"]) {
    id
    firstName
    lastName
    email
    profilePhoto
  }
}
`

export const UPDATE_COMPANY = gql`
mutation UpdateCompany($company: UpdateCompanyInput!) {
  updateCompany(input: $company) {
    company {
      id
      name
    }
  }
}
`;

export const CREATE_SUBSCRIPTION = gql`
mutation CreateCompanySubscription($input: CreateCompanySubscriptionInput!) {
  createCompanySubscription(input: $input) {
    currentSubscription {
      id
    }
  }
}
`;

export const UPDATE_COMPANY_SUBSCRIPTION = gql`
mutation UpdateCompanySubscription($input: UpdateCompanySubscriptionInput!) {
  updateCompanySubscription(input: $input) {
    currentSubscription {
      companySeats
      diverSeats
    }
  }
}
`
