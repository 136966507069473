import React, { useState } from 'react';
import { Link } from "@reach/router";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FormGroup, Label, Input } from 'reactstrap';
import { navigate } from '@reach/router';

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  useForm,
  useTextFormInput,
  InputGroupFormFragment,
  TextInputFormFragment,
} from '../../common/forms';
import Header from '../../components/templates/Header';

import { CREATE_SUBSCRIPTION } from '../../common/apiQueries'
import { GET_ADMINS_FOR_COMPANY } from '../../common/adminApiQueries'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function OrderForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [isSaving, setIsSaving] = useState(false);

  const companySeats = useTextFormInput(1, 'companySeats', 'Company Seats', true);
  const diverSeats = useTextFormInput(1, 'diverSeats', 'Diver Seats', true);
  const form = useForm({
    userId: props.admins[0].id,
  });
  const [subscribe, ] = useMutation(CREATE_SUBSCRIPTION, {
    onCompleted(data) {
      setIsSaving(false);

      if (data.errors) {
        alert(data.errors);
      } else {
        navigate(`/companies/${props.companyId}`);
      }
    }
  });

  if (!stripe || !elements) return null;

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true);

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[ERROR]', error);
      setIsSaving(false);
      return;
    }

    subscribe({
      variables: {
        input: {
          ...form.values,
          companyId: props.companyId,
          stripeToken: paymentMethod.id,
          companySeats: parseInt(companySeats.value),
          diverSeats: parseInt(diverSeats.value),
        }
      },
    });
  };

  return(
    <form onSubmit={ onSubmit }>
      <h5>Add New Payment Method</h5>
      <FormGroup>
        <Label htmlFor="admins">Add Payment Method to an Admin</Label>
        <Input type="select" name="userId" id="admins" value={ form.values.userId } onChange={ form.onChange }>
          { props.admins.map((admin) => (<option key={admin.id} value={admin.id}>{admin.email || `${admin.firstName} ${admin.lastName}`}</option>)) }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Credit Card Information</Label>
        <div>
          <CardElement options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
      </FormGroup>
      <TextInputFormFragment name='discountCode' label='Discount Code' value={ form.values.discountCode } onChange={ form.onChange } />
      <InputGroupFormFragment info={ companySeats } />
      <InputGroupFormFragment info={ diverSeats } />
      { !isSaving && <button type="submit" className="btn btn-success">Submit</button> }
      { isSaving &&
        <button class="btn btn-success" type="button" disabled>
          <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
          Processing...
        </button>
      }
    </form>
  );
}

function CompanySubscribe(props) {
  const breadcrumbTrail = [
    ['/', 'Home'],
    ['/companies', 'Companies'],
    ['', 'Subscribe'],
  ];

  const { loading, error, data } = useQuery(GET_ADMINS_FOR_COMPANY, { variables: { companyId: props.companyId } });
  if (loading) return null;
  if (error) return <div>There was an error. { error.message }</div>;

  const admins = data.listCompanyAdmins;
  if (admins.length === 0) {
    return (
      <div>
        <Header title='Create Subscription'
          breadcrumbs={ breadcrumbTrail }
        />
        <div className='card'>
          <div className='card-body'>
            This company has no admins. Please add an admin.
            <Link to={`/companies/${props.companyId}/members/new`} className='btn btn-success btn-sm ml-3'>Add New Admin</Link>
          </div>
        </div>
      </div>
    )
  }

  // TODO: support when a company quits and comes back
  return (
    <div>
      <Header title='Create Subscription'
        breadcrumbs={ breadcrumbTrail }
      />
      <div className='card'>
        <div className='card-body'>
          <Elements stripe={stripePromise}>
            <OrderForm companyId={ props.companyId } admins={ admins }/>
          </Elements>
        </div>
      </div>
    </div>
  );
}

export default CompanySubscribe;
