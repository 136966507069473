import React, { Fragment } from 'react';
import { useQuery, useMutation} from '@apollo/react-hooks';
import InputMask from "react-input-mask";
import { navigate } from '@reach/router';
import { FormGroup, Label, Input } from 'reactstrap';

import { GET_COMPANIES } from '../../common/adminApiQueries';
import { CREATE_DIVER } from '../../common/apiQueries';
import Header from '../../components/templates/Header';
import { useForm, useTextFormInput, TextInputFormFragment } from '../../common/forms';

function CompanyOptions(props) {
  const { loading, error, data } = useQuery(GET_COMPANIES);
  if (loading) return null;
  if (error) return <div>Error {error.message}</div>;

  const companies = data.listCompanies;

  return (
    <Fragment>
      {companies.map((company) => (<option key={`company${company.id}`} value={company.id}>{company.name}</option>))}
    </Fragment>
  );
}

function DiverNew(props) {
  const [addDiver, ] = useMutation(CREATE_DIVER, {
    onCompleted(data) {
      if (data.errors) {
        console.log(data.errors);
      } else {
        navigate(`/divers/${data.createUser.user.id}`);  // TODO: Update to redirect to that diver's profile
      }
    }
  });

  const breadcrumbTrail = [
    ['/', 'Home'],
    ['/divers', 'Divers'],
    ['', 'Add New Diver']
  ];

  const formValues = useForm();
  const companyId = useTextFormInput('', 'company', 'Company', false);
  const companyRoles = ['diver'];

  const onSubmit = (e) => {
    e.preventDefault();
    let companyIdSubmission = [props.companyId];
    if (props.isAdmin) {
      companyIdSubmission = [companyId.value];
    }

    addDiver({
      variables: {
        user: {
          ...formValues.values,
          companies: companyIdSubmission,
          companyRoles: companyRoles,
        }
      }
    });
  }

  return (
    <div>
      <Header title="Add New Diver" breadcrumbs={breadcrumbTrail} />
      <div className="card">
        <div className="card-body">
          <form onSubmit={ onSubmit }>
            { props.isAdmin &&
            <FormGroup>
              <Label for="company">Company</Label>
              <Input type="select" name="companyId" id="company" value={ companyId.value } onChange={ companyId.onChange }>
                <option key="empty" value="">Unaffiliated</option>
                <CompanyOptions />
              </Input>
            </FormGroup>
            }
            <div className="form-row">
              <TextInputFormFragment className='col-md-6' name='firstName' label='First Name' value={ formValues.values.firstName } onChange={ formValues.onChange } />
              <TextInputFormFragment className='col-md-6' name='lastName' label='Last Name' value={ formValues.values.lastName } onChange={ formValues.onChange } />
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor="attr_email" className="col-form-label">Email</label>
                <input type="email" className='form-control' id='attr_email' name='email' value={ formValues.values.email } onChange={ formValues.onChange } required={ true } />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor="attr_phone_number" className="col-form-label">Phone Number</label>
                <InputMask className='form-control' id='attr_phone_number' mask='(999) 999-9999' name='phone' value={ formValues.values.phone } onChange={ formValues.onChange } />
              </div>
            </div>
            <TextInputFormFragment name='street' label='Street' value={ formValues.values.street } onChange={ formValues.onChange } />
            <div className="form-row">
              <TextInputFormFragment className='col-md-4' name='city' label='City' value={ formValues.values.city } onChange={ formValues.onChange } />
              <TextInputFormFragment className='col-md-2' name='state' label='State' value={ formValues.values.state } onChange={ formValues.onChange } />
              <div className="form-group col-md-2">
                <label htmlFor="attr_zip" className="col-form-label">Zip</label>
                <InputMask className='form-control' id='attr_zip' mask='99999' name='zip' value={ formValues.values.zip } onChange={ formValues.onChange } />
              </div>
              <TextInputFormFragment className='col-md-4' name='country' label='Country' value={ formValues.values.country } onChange={ formValues.onChange } />
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DiverNew;
