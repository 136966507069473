import React, { Fragment, useState } from 'react'
import { Storage } from 'aws-amplify'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import { FormGroup, Label, Input } from 'reactstrap';
import { v4 as uuid } from 'uuid';

import {
  useForm,
  useTextFormInput,
} from '../common/forms';
import {
  CREATE_DIVE,
  GET_DIVERS,
} from '../common/apiQueries';

import { GET_COMPANIES } from '../common/adminApiQueries';

import {
  APPARATUS_VALUES,
  CONDITION_VALUES,
  CURRENT_VALUES,
  DECOMPRESSION_VALUES,
  DIVE_TASKS_MAPPING,
  DIVE_TYPE_VALUES,
  DIVE_TYPE_VALUE_MAP,
  MIX_VALUES,
  TEMPERATURE_VALUES,
  VISIBILITY_VALUES,
} from "../utils/constants";

const AWS_SECURITY_LEVEL = 'public';

function CompanyOptions(props) {
  const { loading, error, data } = useQuery(GET_COMPANIES);
  if (loading) return null;
  if (error) return <div>Error {error.message}</div>;

  const companies = data.listCompanies;

  return (
    <Input type="select" name="companyId" id="company" value={ props.companyId.value } onChange={ props.companyId.onChange }>
      <option key="empty" value="">Unaffiliated</option>
      {companies.map((company) => (<option key={`company${company.id}`} value={company.id}>{company.name}</option>))}
    </Input>
  );
}

function Divers({ companyId, value, onChange }) {
  const variables = {};
  if (companyId) {
    variables.companyId = companyId;
  }

  const { loading, error, data} = useQuery(GET_DIVERS, { variables });

  if (loading) return null;
  if (error) return <div>Problem loading your divers because {error.message}!</div>

  const divers = data.listUsers || [];

  return (
    <FormGroup>
      <Label for="dive_diver">Diver</Label>
      <Input type="select" name="userId" id="dive_diver" value={ value || '' } onChange={ onChange }>
        { divers.map((diver) => (<option key={diver.id} value={diver.id}>{diver.email || (diver.firstName + ' ' + diver.lastName)}</option>)) }
      </Input>
    </FormGroup>
  );
}

function useFormState(initialValue) {
  const [value, setValue] = useState(initialValue);

  function handleChange(e) {
    setValue(e.target.value);
  }

  return {
    value,
    onChange: handleChange,
  };
}

function useFileInputState() {
  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(e.target.files[0]);
  }

  return {
    file,
    onChange: handleChange,
  }
}

function SelectGroup({ attributeName, labelText, value, onChange, optionValues }) {
  return (
    <FormGroup>
      <Label for={`attr_${attributeName}`}>{labelText}</Label>
      <Input type="select" name={`${attributeName}`} id={`attr_${attributeName}`} value={ value || '' } onChange={ onChange }>
        <option value=""></option>
        { optionValues.map((value) => (<option key={value} value={value}>{value}</option>)) }
      </Input>
    </FormGroup>
  );
}

const diveTaskKeys = Object.keys(DIVE_TASKS_MAPPING);

function DiveTask(prop) {
  const value = prop.values.values[prop.name];
  const onChange = prop.values.onChange;
  return (
    <div className='col-auto'>
      <div className="mb-2 custom-control custom-checkbox">
        <input type="checkbox" className="custom-control-input" checked={ value || false } onChange={ onChange} name={prop.name} id={ prop.name } />
        <label className="custom-control-label" htmlFor={ prop.name }>{prop.label}</label>
      </div>
    </div>
  );
}

function DiveTasks(props) {
  return (
    <Fragment>
      <div className='form-row'>
        <label>Dive Tasks</label>
      </div>
      <div className='form-row align-items-center'>
        { diveTaskKeys.map(diveTaskKey => 
        <DiveTask key={ diveTaskKey } name={ diveTaskKey } values={props.values} label={ DIVE_TASKS_MAPPING[diveTaskKey] } />
        ) }
      </div>
    </Fragment>
  );
}

const MAPBOX_CONTEXT_MAPPINGS = {
  'place': 'city',
  'region': 'state',
  'postcode': 'zip',
}

function parseMapboxContext(context) {
  const mapData = {};

  context.forEach(item => {
    const contextId = item.id;
    const periodIndex = contextId.indexOf('.');

    let key = '';
    if (periodIndex === -1) {
      key = contextId;
    } else {
      key = contextId.substring(0, periodIndex);
    }

    if (key in MAPBOX_CONTEXT_MAPPINGS) {
      mapData[MAPBOX_CONTEXT_MAPPINGS[key]] = item.text;
    }
  });

  return mapData;
}

function buildImageLogKey(cognitoUser) {
  return `${cognitoUser.attributes.sub}/dive-${uuid()}.jpg`;
}

function DiveForm(props) {
  const companyId = useTextFormInput(props.companyId || '', 'company', 'Company', false);

  const diverId = useFormState();
  const decompression = useFormState();
  const breathingApparatus = useFormState();
  const breathingMixture = useFormState();
  const condition = useFormState();
  const temperature = useFormState();
  const visibility = useFormState();
  const current = useFormState();
  const diveLogImage = useFileInputState();
  const formValues = useForm();
  const taskValues = useForm();

  const getLocationFromApi = async (event) => {
    const lng = formValues.values.lng;
    const lat = formValues.values.lat;

    if (!lng || !lat) {
      return;
    }

    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?types=poi&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
    )

    const responseJson = await response.json();

    if (responseJson.features.length === 0) {
      formValues.updateValues(
        {
          ...formValues.values,
          city: 'N\\A',
          state: 'N\\A',
          zip: 'N\\A',
        }
      );
      return;
    }

    const mapData = parseMapboxContext(responseJson.features[0].context);
    // location: responseJson.features[0].place_name,
    formValues.updateValues(
      {
        ...formValues.values,
        ...mapData,
      }
    );
  }

  const selectFields = [
    ['Decompression', 'decompression', DECOMPRESSION_VALUES, decompression],
    ["Breathing Apparatus", "breathingApparatus", APPARATUS_VALUES, breathingApparatus],
    ["Breathing Mixture", "breathingMixture", MIX_VALUES, breathingMixture],
    ["Condition", "condition", CONDITION_VALUES, condition],
    ["Temperature", "temperature", TEMPERATURE_VALUES, temperature],
    ["Visibility", "visibility", VISIBILITY_VALUES, visibility],
    ["Current", "current", CURRENT_VALUES, current],
  ];
  const selectForms = [];
  for (let selectField of selectFields) {
    selectForms.push(
      <SelectGroup key={ selectField[2] } labelText={ selectField[0] } attributeName={ selectField[1] } optionValues={ selectField[2] } { ...selectField[3] } />
    )
  }

  const [addDive, ] = useMutation(CREATE_DIVE, {
    onCompleted(response) {
      const dive = response.createDive.dive;

      if (response.errors) {
        console.log(response.errors);
        return;
      }

      navigate(`/dives/${dive.id}`);  // TODO: Update to redirect to that diver's profile
    }
  });

  const onSubmit = async function(e) {
    e.preventDefault();

    const diveTaskValues = [];
    diveTaskKeys.forEach(diveTaskKey => {
      if (taskValues.values[diveTaskKey]) {
        diveTaskValues.push(DIVE_TASKS_MAPPING[diveTaskKey])
      }
    });

    const { lat, lng } = formValues.values;
    const latFloat = lat ? parseFloat(lat) : undefined;
    const lngFloat = lng ? parseFloat(lng) : undefined;

    const formattedDiveDateTime = `${formValues.values.diveDateTime} 00:00:00`; //TODO: may need to modify timezone based on location

    const imageKey = buildImageLogKey(props.cognitoUser);
    if (diveLogImage.file) {
      await Storage.put(imageKey, diveLogImage.file, {
        contentType: 'image/jpeg',
        level: AWS_SECURITY_LEVEL,
      });
    }

    addDive({
      variables: {
        dive: {
          ...formValues.values,
          decompression: decompression.value,
          breathingApparatus: breathingApparatus.value,
          breathingMixture: breathingMixture.value,
          condition: condition.value,
          temperature: temperature.value,
          visibility: visibility.value,
          current: current.value,
          logImage: imageKey,
          taskPerformed: diveTaskValues,
          companyId: companyId.value,
          userId: diverId.value,
          lat: latFloat, // overriding value on purpose
          lng: lngFloat,// overriding value on purpose
          diveDateTime: formattedDiveDateTime, // overriding value on purpose
        }
      }
    });
  };

  return (
    <form onSubmit={ onSubmit }>
      { props.isAdmin && 
      <FormGroup>
        <Label for="company">Company</Label>
        <CompanyOptions companyId={companyId} />
      </FormGroup>
      }
      <Divers { ...diverId } companyId={ companyId.value } />
      <div className='form-row'>
        <div className='form-group col-md-12'>
          <label htmlFor='attr_date_picker' className='col-form-label'>Date</label>
          <Input type="date" className='form-control' name='diveDateTime' value={ formValues.values.diveDateTime } onChange={ formValues.onChange } />
        </div>
      </div>
      <div className='form-row'>
        <div className="form-group col-md-6">
          <label htmlFor="attr_lat" className="col-form-label">Latitude</label>
          <input type="text" className="form-control" id="attr_lat" name='lat' value={ formValues.values.lat } onChange={ formValues.onChange } onBlur={ getLocationFromApi } />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="attr_lng" className="col-form-label">Longitude</label>
          <input type="text" className="form-control" id="attr_lng" name='lng' value={ formValues.values.lng } onChange={ formValues.onChange } onBlur={ getLocationFromApi } />
        </div>
      </div>
      <div className='form-row'>
        {/* <div className='form-group col-md-12'>
          <h5>Location will be populated by entering in the Latitude and Longitude coordinates above</h5>
        </div> */}
        <div className="form-group col-md-6">
          <label htmlFor="attr_city" className="col-form-label">City</label>
          { !formValues.values.city && <input type="text" className="form-control" id="attr_city" disabled={ true } /> }
          { formValues.values.city && <p>{ formValues.values.city }</p> }
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="attr_state" className="col-form-label">State</label>
          { !formValues.values.state && <input type="text" className="form-control" id="attr_state" disabled={ true } /> }
          { formValues.values.state && <p>{ formValues.values.state}</p> }
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="attr_zip" className="col-form-label">Zip</label>
          { !formValues.values.zip && <input type="text" className="form-control" id="attr_zip" disabled={ true } /> }
          { formValues.values.zip && <p>{ formValues.values.zip}</p> }
        </div>
      </div>

      <FormGroup>
        <Label for="dive_job_type">Job Type</Label>
        <Input type="select" name="jobType" id="dive_job_type" value={ formValues.values.jobType || '' } onChange={ formValues.onChange }>
          <option value=""></option>
          { DIVE_TYPE_VALUES.map((value) => (<option key={ DIVE_TYPE_VALUE_MAP[value] } value={ DIVE_TYPE_VALUE_MAP[value] }>{value}</option>)) }
        </Input>
      </FormGroup>
      <DiveTasks values={ taskValues } />
      { selectForms }
      <FormGroup>
        <Label for="dive_logImage">Dive Log Image</Label>
        <Input type="file" name="diveLogImage" id="dive_logImage" onChange={ diveLogImage.onChange } />
      </FormGroup>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
  );
}

export default DiveForm;
