import 'bootstrap/dist/css/bootstrap.min.css';
// TODO: move to a bootstrap or vendor folder...
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'rsuite/dist/rsuite.min.css'

import './assets/css/app.css';
import './assets/css/custom.css';
import './assets/css/icons.css';

import Amplify from 'aws-amplify'
import config from './aws-exports'
import React from 'react';
import ReactDOM from 'react-dom';
import { withAuthenticator } from 'aws-amplify-react';
import registerServiceWorker from './registerServiceWorker';

import './index.css';
import Auth from '@aws-amplify/auth';

import SessionManager from './SessionManager';

Amplify.configure(config);
Auth.configure(config);

const AppWithAuth = withAuthenticator(SessionManager);

const map = (message) => {
  return message;
};

ReactDOM.render(<AppWithAuth includeGreetings={true} errorMessage={map} />, document.getElementById('root'));
registerServiceWorker();
