import React from "react";
import { RangeSlider as RSuiteRangeSlider } from "rsuite";

export default function RangeSlider(props) {
  return (
    <>
      {!!props.label && <label>{props.label}</label>}
      <div className={"mx-3"}>
        <div className={"row justify-content-between"}>
          <div>
            <h5>{props.min || 0}</h5>
          </div>
          <div>
            <h5>{props.max || 100}</h5>
          </div>
        </div>
      </div>
      <div className={"mx-2"}>
        <RSuiteRangeSlider
          min={props.min || 0}
          max={props.max || 100}
          progress
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    </>
  );
}
