import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import Header from '../components/templates/Header';
import CompanyUserList from '../components/CompanyUserList';
import CompanyProfileCard from '../components/CompanyProfileCard';
import { GET_COMPANY } from '../common/apiQueries';

function Account(props) {
  const { loading, error, data } = useQuery(GET_COMPANY, { variables: { id: props.companyId } });

  if (loading) return null;
  if (error) return <div>There was an error {error.message}</div>;

  const company = data.getCompany;
  if (!company) return <div>No company found</div>;

  const breadcrumbTrail = [
    ['/', 'Home'],
    ['', 'Account']
  ];

  return (
    <div>
      <Header title={ 'Account' } breadcrumbs={ breadcrumbTrail } />
      <div className='row'>
        <div className='col-lg-12'>
          <CompanyProfileCard company={ company } />
        </div>
        <div className='col-lg-8'>
          <CompanyUserList users={ data.listUsers } companyId={ props.companyId } isAdmin={ props.isAdmin } />
        </div>
      </div>
    </div>
  );
}

export default Account;
