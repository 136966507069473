import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Chart from 'react-apexcharts';
import BootstrapTable from 'react-bootstrap-table-next';
import { navigate } from "@reach/router";

import { DASHBOARD_QUERY, DASHBOARD_QUERY_WITH_COMPANY } from '../common/apiQueries';
import Header from '../components/templates/Header';
import Card from '../components/Card';

import defaultAvatar from '../assets/images/users/avatar-1.jpg';

function visitItem(event, row, rowIndex) {
  navigate(`/divers/${row.id}`);
}

function avatarFormatter(_, row) {
  return (
    <span className="table-user">
      <img src={ row.profilePhoto || defaultAvatar } alt="" className="rounded-circle" />
    </span>
  );
}


function DiversList(props) {
  const rowEvents = {
    onClick: visitItem,
  }

  const columns = [
    {
      text: "",
      dataField: "image",
      formatter: avatarFormatter,
      headerStyle: (col, colIndex) => {
        return { width: "60px", textAlign: "center" };
      },
    },
    {
      text: "First Name",
      dataField: "firstName",
      headerStyle: (col, colIndex) => {
        return { width: "120px" };
      },
    },
    {
      text: "Last Name",
      dataField: "lastName",
      headerStyle: (col, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      text: "Email",
      dataField: "email",
      headerStyle: (col, colIndex) => {
        return { width: "auto" };
      },
    },
    // {
    //   text: "Active Certs",
    //   dataField: "availability",
    //   headerStyle: (col, colIndex) => {
    //     return { width: "auto" };
    //   },
    // },
    {
      text: "Status",
      dataField: "availability",
      headerStyle: (col, colIndex) => {
        return { width: "60xpx" };
      },
    },
  ];

  return (
    <BootstrapTable
      bootstrap4
      condensed
      bordered={ false }
      keyField='id'
      data={ props.divers }
      columns={ columns }
      rowEvents={ rowEvents }
      wrapperClasses={'table-hover'}
    />
  );
}

const MONTHS = [ "January", "February", "March", "April", "May", "June", 
               "July", "August", "September", "October", "November", "December" ];

function oneYearAgo() {
  const today = new Date();
  return new Date(today.getFullYear() - 1, today.getMonth() + 1, 1);
}

function transformDiverAndDives(data) {
  const xaxisLabels = [];
  const yaxisDiverCount = [];
  const yaxisDiveCount = [];

  const startingDate = oneYearAgo();

  const dataMapping = {};

  data.forEach((row) => {
    if (dataMapping[row.year] === undefined) {
      dataMapping[row.year] = {};
    }

    dataMapping[row.year][row.month - 1] = {
      dives: row.dive_count,
      divers: row.diver_count,
    }
  });

  for (let i = 0; i < 12; i++) {
    const statsMonthYear = new Date(startingDate.getFullYear(), startingDate.getMonth() + i, startingDate.getDate());
    xaxisLabels.push(MONTHS[statsMonthYear.getMonth()]);
    const mappingValues = dataMapping[statsMonthYear.getFullYear()]?.[statsMonthYear.getMonth()];
    if (mappingValues) {
      const diverCount = mappingValues.divers ? mappingValues.divers : 0;
      const diveCount = mappingValues.dives ? mappingValues.dives : 0;

      yaxisDiverCount.push(diverCount);
      yaxisDiveCount.push(diveCount);
    } else {
      yaxisDiverCount.push(0);
      yaxisDiveCount.push(0);
    }
  }

  return {
    xaxisLabels,
    yaxisDiverCount,
    yaxisDiveCount,
  };
}

function Dashboard(props) {
  const variables = props.companyId ? { companyId: props.companyId } : {};
  const query = props.companyId ? DASHBOARD_QUERY_WITH_COMPANY : DASHBOARD_QUERY;

  const { loading, error, data } = useQuery(query, { variables: variables });

  if (loading) return null;
  if (error) return <div>Error {error.message}. Please try again.</div>;

  const graphData = transformDiverAndDives(data.getCountsByMonthYear);

  const options = {
    chart: {
      id: "dashboard-chart",
      width: "100%",
      type: "bar",
    },
    xaxis: {
      categories: graphData.xaxisLabels,
    },
     responsive: [
    {
      breakpoint: 1000,
      options: {
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        legend: {
          position: "bottom"
        }
      }
    }
  ]
  };

  const series = [{
      name: 'Divers',
      data: graphData.yaxisDiverCount,
    }, {
      name: 'Dives',
      data: graphData.yaxisDiveCount,
    },
  ];

  const dashboardTitle = data.getCompany ? `${ data.getCompany.name } DASHBOARD` : 'DASHBOARD';

  return (
    <div>
      <Header title={dashboardTitle} />
      <div className="row">
        <div className="col-md-2">
          <Card title="divers">
            <h2>{data.getDiverCount}</h2>
          </Card>
          <Card title="dives">
            <h2>{data.getDiveCount}</h2>
          </Card>
        </div>
        <div className="col-md-10">
          <div className="card">
            <div className="card-body" style={{height:"350px"}}>
              <h4 className="header-title mt-0 mb-0">Activity</h4>
              <Chart options={options} series={series} type="bar" height={280}/>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Card title="roster">
            <DiversList divers={data.listUsers} />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
