import { useQuery } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import { flatten, get, maxBy, minBy, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { GET_COMPANY_DIVER_EXPERIENCES } from "../../common/apiQueries";
import DiverExperienceCard from "../../components/DiverExperienceCard";
import FilterBar, { FILTER_TYPES } from "../../components/FilterBar";
import Header from "../../components/templates/Header";
import { filterDiversExperiences } from "../../services/filter-divers-experiences";

const breadcrumbTrail = [
  ["/", "Home"],
  ["/divers", "Divers"],
  ["", "Filter"],
];

function mapLabelValue(value) {
  return { label: value, value };
}

function mapFilterOption(dives = [], prop) {
  const data = uniq(flatten(dives.map((x) => x[prop])));
  return data.filter((x) => !!x).map(mapLabelValue);
}

function mapDiveExperience(diverExperience) {
  diverExperience.name = "";
  if (diverExperience.user.firstName !== null) {
    diverExperience.name = diverExperience.user.firstName;
  }
  if (diverExperience.user.lastName !== null) {
    if (diverExperience.name !== "") {
      diverExperience.name += " ";
    }

    diverExperience.name += diverExperience.user.lastName;
  }

  diverExperience.userId = diverExperience.user.id;
  if (diverExperience.maximumDepth === -1) {
    diverExperience.maximumDepth = null;
  }

  if (diverExperience.timeSpentAtBottom === -1) {
    diverExperience.timeSpentAtBottom = null;
  }

  return diverExperience;
}

function InternalDiverSearch() {
  const [filter, setFilter] = useState({
    name: "",
    divesPerformed: [],
    maximumDepth: [],
    timeSpentAtBottom: [],
    diveType: [],
    decompression: [],
    breathingApparatus: [],
    breathingMixture: [],
    condition: [],
    temperature: [],
    visibility: [],
    current: [],
    taskPerformed: [],
  });
  const [dives, setDives] = useState([]);
  const { loading, error, data } = useQuery(GET_COMPANY_DIVER_EXPERIENCES);

  function getDiversExperiencesSource() {
    return (data ? data.listCompanyDivers || [] : [])
      .map(mapDiveExperience)
      .filter((d) => !!d.name);
  }

  function onFilterChange(filterValue) {
    const dataSource = getDiversExperiencesSource();
    setDives(filterDiversExperiences(dataSource, filterValue));
  }

  function filterByName(name) {
    let newFilterValue = {
      ...filter,
      name: name,
    };
    setFilter(newFilterValue);
    onFilterChange(newFilterValue);
  }

  useEffect(() => {
    setDives(getDiversExperiencesSource());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return null;
  if (error) return <div>There was an issue {error.message}</div>;

  return (
    <>
      <Header
        title="Search Divers by Dive History"
        breadcrumbs={breadcrumbTrail}
      />
      <div className="row">
        <div className="col-3">
          <input
            type="text"
            className="form-control"
            placeholder={"Search by name"}
            onChange={(e) => filterByName(e.target.value)}
          />
        </div>
      </div>
      <div className="my-3">
        <FilterBar
          value={filter}
          onChange={(newValue) => {
            setFilter(newValue);
            onFilterChange(newValue);
          }}
          filters={[
            {
              key: "divesPerformed",
              label: "Dives",
              type: FILTER_TYPES.numberRange,
              options: {
                min: get(
                  minBy(getDiversExperiencesSource(), "divesPerformed"),
                  "divesPerformed"
                ),
                max: get(
                  maxBy(getDiversExperiencesSource(), "divesPerformed"),
                  "divesPerformed"
                ),
              },
            },
            {
              key: "maximumDepth",
              label: "Max. Depth",
              type: FILTER_TYPES.numberRange,
              options: {
                min: get(
                  minBy(getDiversExperiencesSource(), "maximumDepth"),
                  "maximumDepth"
                ),
                max: get(
                  maxBy(getDiversExperiencesSource(), "maximumDepth"),
                  "maximumDepth"
                ),
              },
            },
            {
              key: "diveType",
              label: "Types",
              type: FILTER_TYPES.multiSelect,
              options: mapFilterOption(
                getDiversExperiencesSource(),
                "diveType"
              ),
            },
            {
              key: "timeSpentAtBottom",
              label: "Max. Time Spent",
              type: FILTER_TYPES.numberRange,
              options: {
                min: get(
                  minBy(getDiversExperiencesSource(), "timeSpentAtBottom"),
                  "timeSpentAtBottom"
                ),
                max: get(
                  maxBy(getDiversExperiencesSource(), "timeSpentAtBottom"),
                  "timeSpentAtBottom"
                ),
              },
            },
            {
              key: "decompression",
              label: "Decompression",
              type: FILTER_TYPES.multiSelect,
              options: mapFilterOption(
                getDiversExperiencesSource(),
                "decompression"
              ),
            },
            {
              key: "breathingApparatus",
              label: "Breathing Apparatus",
              type: FILTER_TYPES.multiSelect,
              options: mapFilterOption(
                getDiversExperiencesSource(),
                "breathingApparatus"
              ),
            },
            {
              key: "breathingMixture",
              label: "Breathing Mixture",
              type: FILTER_TYPES.multiSelect,
              options: mapFilterOption(
                getDiversExperiencesSource(),
                "breathingMixture"
              ),
            },
            {
              key: "condition",
              label: "Condition",
              type: FILTER_TYPES.multiSelect,
              options: mapFilterOption(
                getDiversExperiencesSource(),
                "condition"
              ),
            },
            {
              key: "temperature",
              label: "Temperature",
              type: FILTER_TYPES.multiSelect,
              options: mapFilterOption(
                getDiversExperiencesSource(),
                "temperature"
              ),
            },
            {
              key: "visibility",
              label: "Visibility",
              type: FILTER_TYPES.multiSelect,
              options: mapFilterOption(
                getDiversExperiencesSource(),
                "visibility"
              ),
            },
            {
              key: "current",
              label: "Current",
              type: FILTER_TYPES.multiSelect,
              options: mapFilterOption(getDiversExperiencesSource(), "current"),
            },
            {
              key: "taskPerformed",
              label: "Tasks",
              type: FILTER_TYPES.multiSelect,
              options: mapFilterOption(
                getDiversExperiencesSource(),
                "taskPerformed"
              ),
            },
          ]}
        />
      </div>
      <div className="row">
        {dives &&
          dives.map((dive, index) => (
            <div className="col-4" key={index}>
              <DiverExperienceCard
                dive={dive}
                onClick={() => {
                  navigate(`/divers/${dive.user.id}`);
                }}
              />
            </div>
          ))}
      </div>
    </>
  );
}

export default InternalDiverSearch;
