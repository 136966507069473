import React, { useState } from 'react';

export function useForm(initialValues = {}) {
  const [values, setValue] = useState(initialValues);

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      setValue({ ...values, [event.target.name]: event.target.checked });
    } else {
      setValue({ ...values, [event.target.name]: event.target.value });
    }
  }

  return {
    values,
    onChange: handleChange,
    updateValues: setValue,
  }
}

export function useTextFormInput(initialValue, fieldId, displayName, isRequired) {
  const [value, setValue] = useState(initialValue);

  function handleChange(e) {
    setValue(e.target.value);
  }

  return {
    value,
    onChange: handleChange,
    id: fieldId,
    displayName: displayName,
    isRequired: isRequired,
  };
}

export function TextInputFormFragment(props) {
  let className = 'form-group'
  if (props.className) {
    className = `form-group ${props.className}`;
  }

  let label = props.label || props.displayName || '';

  return (
    <div className={ className }>
      <label htmlFor={ "attr_" + props.name } className='col-form-label'>{ label }</label>
      <input type="text" className="form-control" id={ "attr_" + props.name } name={ props.name } placeholder={ props.placeholder || '' } value={ props.value || '' } onChange={ props.onChange } required={ props.required || false } />
    </div>
  );
}

export function InputGroupFormFragment(props) {
  return (
    <div className="form-group">
      <label htmlFor={ "attr_" + props.info.id }>{ props.info.displayName }</label>
      <input type="text" value={props.info.value} id={ "attr_" + props.info.id } className="form-control" onChange={ props.info.onChange } required={ props.info.isRequired } />
    </div>
  )
}
