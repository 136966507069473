import React, { Component } from 'react';
import { Router } from "@reach/router";
import { ApolloProvider} from '@apollo/react-hooks';

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import './App.css';
import { isCompanyAdmin, isSiteAdmin } from './common/permissions';
import ContentHeader from './components/ContentHeader';
import ContentFooter from './components/ContentFooter';
import LeftNavigation from './components/LeftNavigation';
import Account from './pages/account';
import Companies from './pages/companies/index';
import Company from './pages/companies/show';
import CompanyNew from './pages/companies/new';
import CompanyMembersNew from './pages/companies/members-new';
import CompanySubscribe from './pages/companies/subscribe';
import Dashboard from './pages/dashboard';
import Divers from './pages/divers/index';
import Search from './pages/divers/search';
import InternalDiverSearch from './pages/divers/internal';
import Diver from './pages/divers/show';
import DiverCertificationShow from './pages/divers/diver-certifications-show';
import DiverNew from './pages/divers/new';
import Dives from './pages/dives/index';
import DiveNew from './pages/dives/new';
import Dive from './pages/dives/show';
import Subscribe from './components/Subscribe';
import { ModalProvider } from './components/modal/Modal';

// TODO: redo integration with cognito, apolloprovider, and the router
//       they no longer integrate the router with content
class App extends Component {
  render() {
    const {
      user,
      cognitoUser,
      client,
    } = this.props;

    const companyUserRole = isCompanyAdmin(user);
    const isAdmin = isSiteAdmin(user);

    const companyId = companyUserRole && companyUserRole.company.id;
    return (
      <ApolloProvider client={ client }>
        <ModalProvider>
          <div className="App">
            <LeftNavigation user={ user } />
            <div className="content-page">
              <div id="content">
                <ContentHeader user={ user } />
                <div className="container-fluid">
                  <Router>
                    <Dashboard path="/" companyId={ companyId } />
                    <Account path="account" companyId={ companyId } />
                    <Subscribe path="subscribe" />
                    <Divers path="divers" companyId={ companyId } isAdmin={ false } />
                    <Diver path="divers/:diverId" companyId={ companyId } isAdmin={ false } />
                    <DiverNew path="divers/new" companyId={ companyId } isAdmin={ isAdmin } />
                    <InternalDiverSearch path='divers/internal' companyId={ companyId } isAdmin={ false } />
                    <Search path='divers/search' companyId={ companyId } isAdmin={ false } />
                    <DiverCertificationShow path='divers/:diverId/certifications/:id' companyId={ companyId } />
                    <Dives path="dives" companyId={ companyId } isAdmin={ isAdmin } />
                    <DiveNew path="dives/new" companyId={ companyId } isAdmin={ isAdmin } cognitoUser={ cognitoUser } />
                    <Dive path="/dives/:diveId" companyId={ companyId } isAdmin={ false } />
                    <Companies path="companies" />
                    <CompanyNew path="companies/new" />
                    <Company path="companies/:companyId" />
                    <CompanyMembersNew path="companies/:companyId/members/new" companyId={ companyId } isAdmin={ isAdmin } />
                    <CompanySubscribe path="companies/:companyId/subscribe" companyId={ companyId } />
                  </Router>
                </div>
              </div>
                <ContentFooter />
            </div>
          </div>
        </ModalProvider>
      </ApolloProvider>
    );
  }
}

export default App;
