import React from 'react';

import { DATE_FORMAT } from '../utils/constants';

export function bootstrapTableDateFormatter(cell, row) {
  let formattedDate = cell;
  if (formattedDate) {
    formattedDate = (new Date(cell)).toLocaleDateString("en-US", DATE_FORMAT);
  }

  return (
    <span>{ formattedDate }</span>
  );
}

