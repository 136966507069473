import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { navigate } from "@reach/router";

import BootstrapTable from 'react-bootstrap-table-next';

import { bootstrapTableDateFormatter } from '../../common/tables';
import { DATE_FORMAT } from '../../utils/constants';
import { GET_DIVER, INVITE_DIVER } from '../../common/apiQueries';
import Header from "../../components/templates/Header";
import Card from '../../components/Card';
import DiverCard from "../../components/templates/DiverCard";

function visitItem(event, row, rowIndex) {
  navigate(`/divers/${row.user.id}/certifications/${row.id}`);
}

function rowEvents() {
  return {
    onClick: visitItem,
  }
}

function ProfileHeader(props) {
  const [inviteDiver, ] = useMutation(INVITE_DIVER, { variables: { input: { userId: props.id, companyId: props.companyId } } });

  const inviteButtonHandler = event => {
    event.preventDefault();
    inviteDiver({variables: {
      input: {
        userId: props.id,
        companyId: props.companyId,
      }
    }});
  };

  return (
    <DiverCard title={ `${props.firstName || ''} ${props.lastName || ''}`} profilePhoto={ props.profilePhoto } city={ props.city } state={ props.state } joinedAt={ props.createdAt }>
      { !props.isTeamMember && <button className="btn btn-secondary" onClick={ inviteButtonHandler }>Invite to Join Company</button> }
    </DiverCard>
  );
}

function viewingAsTeamMember(companyUserRole, companyUserInvitations = [], companyId) {
  let result = false;

  if (companyUserRole) {
    result = true;
  } else {
    const companyUserInvitation = companyUserInvitations.find(companyUserInvitation =>
      companyUserInvitation.company.id === companyId && companyUserInvitation.respondedAt === undefined
    );

    if (companyUserInvitation) {
      result = true;
    }
  }

  return result;
}

function visitDive(event, row, rowIndex) {
  navigate(`/dives/${row.id}`);
}

function Dives(props) {
  if (!props.dives) return null;
  // TODO: set permissions on if the current user can create a dive for the diver

  const diveColumns = [
    { text: 'City', dataField: 'city' },
    { text: 'State', dataField: 'state' },
    { text: 'Date', dataField: 'diveDateTime', formatter: bootstrapTableDateFormatter },
  ];
  
  const rowEvents = {
    onClick: visitDive,
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="header-title">
          Dives
        </h4>
        <BootstrapTable bootstrap4 
          keyField='id'
          data={ props.dives }
          rowEvents={ rowEvents }
          columns={ diveColumns }
          wrapperClasses={'table-hover'} />
      </div>
    </div>
  );
}

function Certifications(props) {
  const certificationColumns = [
    { text: 'Certification Type', dataField: 'certificationType' },
    { text: 'Issuing Body', dataField: 'issuingBody' },
    { text: 'Date Issued', dataField: 'dateIssue', formatter: bootstrapTableDateFormatter },
    { text: 'Date Expired', dataField: 'dateExpired', formatter: bootstrapTableDateFormatter },
  ];

  return (
    <Card title='certificates'>
      <BootstrapTable bootstrap4 keyField='id'
        data={ props.diverCertifications || [] }
        columns={ certificationColumns }
        rowEvents={ rowEvents() }
        wrapperClasses={'table-hover'}
      />
    </Card>
  );
}

function Companies(props) {
  return (
    <Card title='companies'>
      <div className='table-responsive'>
        <table className="table table-hover table-centered mb-0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Join Date</th>
            </tr>
          </thead>
          <tbody>
            {
              props.companies.map(company => {
                return (<tr>
                  <td>{company.name}</td>
                  <td>{ (new Date(company.createdAt)).toLocaleDateString("en-US", DATE_FORMAT) } </td>
                </tr>)})
            }
          </tbody>
        </table>
      </div>
    </Card>
  );
}

function Profile(props) {
  return (
    <Card title='details'>
      <div className='text-left'>
        <p className='text-muted'>
          <strong>Email: </strong>
          <span className='ml-2'>{props.email}</span>
        </p>
      </div>
    </Card>
  );
}

function CompanyMembership(props) {
  if (props.companyUserRole === undefined) {
    return null;
  }

  return (
    <Card title='company membership'>
      <div className='text-left'>
        <p className='text-muted'>
          <strong>Joined</strong>
          <span className='ml-2'>{ (new Date(props.companyUserRole.createdAt)).toLocaleDateString("en-US", DATE_FORMAT) }</span>
        </p>
      </div>
    </Card>
  );
}

function Diver(props) {
  const { loading, error, data } = useQuery(GET_DIVER, { variables: { id: props.diverId } });

  if (loading) return null;
  if (error) return <div>There was an issue {error.messages}</div>;

  const diver = data.getUser;
  const breadcrumbTrail = [
    ['/', 'Home'],
    ['/divers', 'Divers'],
    ['', 'Diver']
  ];

  const companyUserRoles = diver.companyUserRoles || [];
  const companyUserRole = companyUserRoles.find(userRole => userRole.company.id === props.companyId);
  const isTeamMember = viewingAsTeamMember(companyUserRole, diver.companyUserInvitations, props.companyId);

  return (
    <div>
      <Header title={ `Diver Profile`} breadcrumbs={ breadcrumbTrail } />
      <ProfileHeader {...diver} companyId={ props.companyId } isTeamMember={ isTeamMember } />
      <div className='row'>
        <div className='col-lg-4'>
          <Profile {...diver} />
          { !props.isAdmin && <CompanyMembership companyUserRole={ companyUserRole } /> }
        </div>
        <div className='col-lg-8'>
          { props.isAdmin && <Companies {...diver} /> }
          <Certifications {...diver} />
          <Dives {...diver} />
        </div>
      </div>
    </div>
  );
}

export default Diver;
