import React from 'react';
import { useMutation} from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import { CREATE_DIVER } from '../../common/apiQueries';
import Header from '../../components/templates/Header';
import { useTextFormInput, InputGroupFormFragment } from '../../common/forms';


function CompanyMembersNew(props) {
  let companyPath = '/account';
  if (props.isAdmin) {
    companyPath = `/companies/${props.companyId}`;
  }

  const [addDiver, ] = useMutation(CREATE_DIVER, {
    onCompleted(_) {
      navigate(companyPath);  // TODO: Update to redirect to that diver's profile
    }
  });

  const breadcrumbTrail = [
    ['/', 'Home'],
    [companyPath, 'Company'],
    ['', 'Add New Member']
  ];

  const firstName = useTextFormInput('', 'firstName', 'First Name', true);
  const lastName = useTextFormInput('', 'lastName', 'Last Name', true);
  const street = useTextFormInput('', 'street', 'Street', false);
  const state = useTextFormInput('', 'state', 'State', false);
  const country = useTextFormInput('', 'country', 'Country', false);
  const zip = useTextFormInput('', 'zip', 'Zip', false);
  const phone = useTextFormInput('', 'phone', 'Phone', false);
  const email = useTextFormInput('', 'email', 'Email', true);
  const userCompanyIds = [props.companyId];
  const companyRoles = ['admin'];

  const fieldElements = [];
  const fields = [firstName, lastName, street, state, country, zip, phone, email,];
  for (let field of fields) {
    fieldElements.push(
      <InputGroupFormFragment key={ field.id } info={ field } />
    );
  }

  const onSubmit = (e) => {
    e.preventDefault();
    addDiver({
      variables: {
        user: {
          firstName: firstName.value,
          lastName: lastName.value,
          street: street.value,
          state: state.value,
          country: country.value,
          zip: zip.value,
          phone: phone.value,
          email: email.value,
          companies: userCompanyIds,
          companyRoles: companyRoles,
        }
      }
    });
  }

  return (
    <div>
      <Header title="Add New Member to Your Company" breadcrumbs={breadcrumbTrail} />
      <div className="card">
        <div className="card-body">
          <form onSubmit={ onSubmit }>
            { fieldElements }
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CompanyMembersNew;
