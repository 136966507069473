import gql from 'graphql-tag'

export const getUser = function(cognitoId) {
  return gql`
    {
      getUser(id:null, cognitoId: "${cognitoId}") 
      {
        id
        email
        firstName
        role
        profilePhoto
        companyUserRoles {
          id
          company {
            id
            name
          }
          role
        }
      }
    }
  `;
};
