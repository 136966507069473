import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import { Link, navigate } from "@reach/router";
import BootstrapTable from 'react-bootstrap-table-next';

import { GET_COMPANIES } from "../../common/apiQueries";
import Header from '../../components/templates/Header';


function visitItem(event, row, rowIndex) {
  navigate(`/companies/${row.id}`);
}

function Companies(props) {
  const { loading, error, data } = useQuery(GET_COMPANIES);

  if (loading) return null;
  if (error) return <div>Sorry, there was an error {error.message}.</div>;

  const columns = [
    { text: 'Name', dataField: 'name'},
  ];

  const breadcrumbTrail = [
    ['/', 'Home'],
    ['', 'Companies'],
  ];

  const rowEvents = {
    onClick: visitItem,
  }

  const companies = data.listCompanies;
  if (!companies) return <div>Sorry, there was an error { error.message }.</div>

  return(
    <div>
      <Header title="Companies"
        titleButton={ <Link to='new' className='btn btn-success btn-sm ml-3'>Add New</Link> }
        breadcrumbs={ breadcrumbTrail } />
      <div className="card">
        <div className="card-body">
          <p>Click on a row to view more details</p>
          <BootstrapTable bootstrap4
            keyField='id'
            data={ companies }
            columns={ columns }
            rowEvents={ rowEvents }
            wrapperClasses={'table-hover'} />
        </div>
      </div>
    </div>
  );
}

export default Companies;
