import React from 'react'
import { Link } from '@reach/router';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { GET_COMPANY, UPDATE_COMPANY_SUBSCRIPTION } from '../../common/apiQueries';
import { GET_ADMINS_FOR_COMPANY } from '../../common/adminApiQueries';
import { useForm, TextInputFormFragment } from '../../common/forms'

import Header from '../../components/templates/Header';
import Card from '../../components/Card';
import CompanyProfileCard from '../../components/CompanyProfileCard';
import CompanyUserList from '../../components/CompanyUserList';

function ManageSubscriptionForm(props) {
  const seatForm = useForm({
    companySeats: props.companySeats,
    diverSeats: props.diverSeats,
  });

  const [updateSubscription, ] = useMutation(UPDATE_COMPANY_SUBSCRIPTION, {
    onCompleted(data)  {
      window.location.reload();
    }
  });

  const modifySeats = (event) => {
    event.preventDefault();
    updateSubscription({
      variables: {
        input: {
          id: props.companySubscriptionId,
          companySeats: parseInt(seatForm.values.companySeats),
          diverSeats: parseInt(seatForm.values.diverSeats),
        }
      }
    });
  };

  return (
    <div>
      <form onSubmit={ modifySeats }>
        <div className='form-row'>
          <TextInputFormFragment className='col-md-6' name='companySeats' label='Company Seats' value={ seatForm.values.companySeats } onChange={ seatForm.onChange } />
          <TextInputFormFragment className='col-md-6' name='diverSeats' label='Diver Seats' value={ seatForm.values.diverSeats } onChange={ seatForm.onChange } />
          <button type="submit" className='btn btn-primary'>Submit</button>
        </div>
      </form>
    </div>
  );
}

function Company(props) {
  const { loading, error, data } = useQuery(GET_COMPANY, { variables: { id: props.companyId } });
  const { loading: adminLoading, error: adminError, data: adminsData } = useQuery(GET_ADMINS_FOR_COMPANY, { variables: { companyId: props.companyId } });

  if (loading || adminLoading) return null;
  if (error || adminError) return <div>There was an issue {error.message}</div>;

  const company = data.getCompany;
  const users = data.listUsers;
  const admins = adminsData.listCompanyAdmins;

  const breadcrumbTrail = [
    ['/', 'Home'],
    ['/companies', 'Companies'],
    ['', 'View Company']
  ];

  return (
    <div>
      <Header title={'Company Profile'} breadcrumbs={ breadcrumbTrail } />
      <div className='row'>
        <div className='col-lg-12'>
          <CompanyProfileCard company={ company } />
          <Card title='Manage Subscription'>
            {
              !company.currentSubscription &&
                <Link to={`/companies/${props.companyId}/subscribe`} className='btn btn-success btn-sm ml-3'>Subscribe</Link>
            }
            {
              company.currentSubscription &&
                <div>
                  <ManageSubscriptionForm companySubscriptionId={ company.currentSubscription.id } companySeats={ company.currentSubscription.companySeats } diverSeats={ company.currentSubscription.diverSeats } />
                  {
                    admins.map((admin) => {
                      return admin.paymentMethods.map((pm) => {
                      return (
                        <div key={ pm.id }>
                          Credit card ending in { pm.last4 }.
                        </div>
                      ); })
                    })
                  }
                </div>
            }
          </Card>
          <CompanyUserList users={ users || [] } companyId={ props.companyId } isAdmin={ props.isAdmin } />
        </div>
      </div>
    </div>);
}

export default Company;
