import { take } from "lodash";
import React, { useContext, useState } from "react";
import { Checkbox, CheckboxGroup } from "rsuite";
import Dialog from "./modal/Dialog";
import { ModalContext } from "./modal/Modal";
import RangeSlider from "./RangeSlider";

export const FILTER_TYPES = {
  numberRange: "number-range",
  multiSelect: "multi-select",
};

function InlineFilter({ value, onChange, filter }) {
  if (filter.type === FILTER_TYPES.numberRange) {
    const filterValue =
      value && value.length ? value : [0, filter.options.max || 100];
    return (
      <div className={"mx-2"}>
        <RangeSlider
          label={filter.label}
          min={0}
          max={filter.options.max || 100}
          value={filterValue}
          onChange={onChange}
        />
      </div>
    );
  }

  if (filter.type === FILTER_TYPES.multiSelect) {
    return (
      <CheckboxGroup
        inline
        name="checkboxList"
        value={value}
        onChange={onChange}
      >
        {filter.options &&
          filter.options.map((opt, index) => (
            <Checkbox key={index} value={opt.value}>
              {opt.label}
            </Checkbox>
          ))}
      </CheckboxGroup>
    );
  }
}

function NumericFilterModal({ value, filter, onChange }) {
  const [values, setValues] = useState(getInitialValue());

  function getInitialValue() {
    if (value && value.length) {
      return value;
    }
    return filter.options ? [0, filter.options.max || 100] : [0, 100];
  }

  return (
    <Dialog
      title={filter.label}
      onConfirm={() => {
        onChange && onChange(values);
      }}
      cancelLabel={"Clear"}
      onCancel={() => {
        onChange && onChange([]);
      }}
    >
      <div className={"my-3 mx-2"}>
        <RangeSlider
          min={0}
          max={filter.options.max || 100}
          value={values}
          onChange={(value) => setValues(value)}
        />
      </div>
    </Dialog>
  );
}

function MultipleSelectFilterModal({ value, filter, onChange }) {
  const [values, setValues] = useState(value || []);

  return (
    <Dialog
      title={filter.label}
      confirmLabel={"Apply"}
      onConfirm={() => {
        onChange && onChange(values);
      }}
      cancelLabel={"Clear"}
      onCancel={() => {
        onChange && onChange([]);
      }}
    >
      <CheckboxGroup
        name="checkboxList"
        value={values}
        onChange={(value) => {
          setValues(value);
        }}
      >
        {filter.options &&
          filter.options.map((opt, index) => (
            <Checkbox key={index} value={opt.value}>
              {opt.label}
            </Checkbox>
          ))}
      </CheckboxGroup>
    </Dialog>
  );
}

function FilterBar(props = { value: {}, filters: [], onChange: () => {} }) {
  const { openModal } = useContext(ModalContext);
  const filters = props.filters || [];

  function filterIsSelected(filterKey) {
    return (
      props.value && props.value[filterKey] && props.value[filterKey].length
    );
  }

  function showFilterModal(filter, onChange) {
    switch (filter.type) {
      case FILTER_TYPES.numberRange: {
        openModal({
          content: (
            <NumericFilterModal
              value={props.value[filter.key]}
              filter={filter}
              onChange={onChange}
            />
          ),
        });
        return;
      }

      case FILTER_TYPES.multiSelect: {
        openModal({
          content: (
            <MultipleSelectFilterModal
              value={props.value[filter.key]}
              filter={filter}
              onChange={onChange}
            />
          ),
        });
        return;
      }

      default:
        return;
    }
  }

  function handleChanges(filterKey, value) {
    props.onChange &&
      props.onChange({
        ...props.value,
        [filterKey]: value,
      });
  }

  function clearFilters() {
    let value = { ...props.value };
    Object.keys(value).forEach((key) => {
      if (value[key] instanceof Array) {
        value[key] = [];
      } else if (typeof value[key] === "boolean") {
        value[key] = false;
      } else {
        value[key] = "";
      }
    });
    props.onChange && props.onChange(value);
  }

  return (
    <div className="bg-white pl-1 pr-3 pt-3 pb-0">
      <div className="row">
        <div className={"col-4"}>
          {take(
            filters.map((filter, index) => (
              <div key={index} className="mb-3">
                <InlineFilter
                  filter={filter}
                  value={props.value[filter.key]}
                  onChange={(newValue) => {
                    handleChanges(filter.key, newValue);
                  }}
                />
              </div>
            )),
            3
          )}
        </div>
        <div className="col-8">
          <div className="row">
            {filters.slice(3, filters.length).map((filter, index) => (
              <div className="col-4 mb-3" key={index}>
                <button
                  type="button"
                  className={`w-100 d-flex btn ${
                    filterIsSelected(filter.key)
                      ? "btn-success"
                      : "btn-outline-success"
                  }`}
                  onClick={() =>
                    showFilterModal(filter, (newValue) => {
                      handleChanges(filter.key, newValue);
                    })
                  }
                >
                  <div className={"flex-fill"}>{filter.label}</div>
                  <span className="ml-2 dripicons-chevron-down"></span>
                </button>
              </div>
            ))}
          </div>
          <div className="row">
            <div className={"col-4"}>
              <button
                type="button"
                className="w-100 btn btn-outline-secondary"
                onClick={clearFilters}
              >
                Clear filters
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterBar;
